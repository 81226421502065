export abstract class APIError extends Error {
  // returns error message returned by API
  abstract get apiMessage (): string | undefined
  // returns error message from API or
  // generic/technical error message
  abstract toString (): string
}

export class APIErrorFetch extends APIError {
  resp: Response
  body?: Record<string, unknown>

  constructor (req: Request, resp: Response) {
    super()
    this.name = 'APIError'
    this.resp = resp
    this.message = `${req.method} ${resp.url}: ${resp.status} ${resp.statusText}`
  }

  async parseBody (): Promise<void> {
    try {
      this.body = await this.resp.json()
    } catch (e) {
      // unable to parse body as json
    }
  }

  get apiMessage (): string | undefined {
    return this.body?.message as string ?? undefined
  }

  toString (): string {
    return this.apiMessage ?? this.message
  }
}

export class APIErrorXHR extends APIError {
  req: XMLHttpRequest
  body?: Record<string, unknown>

  constructor (req: XMLHttpRequest) {
    super()
    this.req = req
    this.body = req.response
    this.message = `${req.responseURL}: ${req.status} ${req.statusText}`
  }

  get apiMessage (): string | undefined {
    return this.body?.message as string ?? undefined
  }

  toString (): string {
    return this.apiMessage ?? this.message
  }
}
