export function setIframeRatio (): void {
  const iframeEl = document.querySelectorAll('iframe')
  if (iframeEl.length > 0) {
    iframeEl.forEach(iframe => {
      const src = iframe.getAttribute('src')
      if (src?.includes('youtube') ?? true) {
        iframe.classList.add('embed-iframe')
      }
    })
  }
}

setIframeRatio()
