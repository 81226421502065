import { APIErrorFetch } from './api-error'

let isUnloading = false
window.addEventListener('beforeunload', () => { isUnloading = true })

// request is a fetch-compatible wrapper which:
// - insert the csrf token in the request
// - throws APIError when the HTTP status code doesn't match
// used to talk to the forum API
export async function request (input: RequestInfo, init?: RequestInit): Promise<Response> {
  const request = new Request(input, init)

  // Add CSRF Token to request
  // django insert an input element which contains the CSRF token
  // using the `{% csrf_token %}` template tag
  const csrfToken = document.querySelector<HTMLInputElement>(
    'input[name="csrfmiddlewaretoken"]')?.value
  if (csrfToken !== undefined) {
    request.headers.set('X-CSRFToken', csrfToken)
  }

  try {
    const resp = await fetch(request)
    if (!resp.ok) {
      const err = new APIErrorFetch(request, resp)
      await err.parseBody()
      throw err
    }
    return resp
  } catch (e: unknown) {
    // check if request was aborted because of navigation change
    const isNavAbort = e instanceof Error && e.name === 'TypeError' &&
      e.message === 'NetworkError when attempting to fetch resource.' &&
      isUnloading
    if (isNavAbort) {
      // returns a promise that never resolves in order to stay in "waiting" state
      // no leak because the page is reloaded after (JS context is garbage collected)
      return await new Promise(() => {})
    }
    throw e
  }
}

// implement the same interface as fetch excepts :
// - it returns parsed JSON
// - the body is serialized to JSON
// - it throws APIError if request failed
// - it accepts two types parameters: response type, request type
// - the body should match the second type parameter
export async function requestJSON<Resp, Body = undefined> (
  input: RequestInfo,
  init?: Omit<RequestInit, 'body'> & { body?: Body }
): Promise<Resp> {
  const fetchInit: RequestInit = {
    ...init,
    ...(init?.body !== undefined ? { body: JSON.stringify(init.body) } : { body: undefined })
  }
  const req = new Request(input, fetchInit)
  req.headers.set('Content-Type', 'application/json')

  const resp = await request(req)
  return await resp.json()
}
