/*
** With Vue 3.2.6, provide/inject is not compatible between Vue's custom elements and Vue components.
** see https://v3.vuejs.org/guide/web-components.html#provide-inject
** see https://github.com/vuejs/vue-next/issues/4476
** deepInject supports inject across Vue components / Web components with a performance overhead.
*/

import { getCurrentInstance, InjectionKey } from 'vue'

export function deepInject<T> (key: InjectionKey<T> | string): T | undefined {
  let inst = getCurrentInstance()
  if (inst === null) {
    throw new Error('getCurrentInstance returned null')
  }
  inst = inst.parent
  if (inst === null) {
    return
  }
  while (inst !== null) {
    // @ts-expect-error
    if (key in inst.provides) {
      // @ts-expect-error
      return inst.provides[key]
    }
    inst = inst.parent
  }
}
