import { ref, reactive } from 'vue'
import { APIError } from './api-error'

export interface UseRequest<T> {
  exec: () => Promise<void>
  data: T | undefined
  err: APIError | undefined
  loading: boolean
}

interface UseRequestEvents<T> {
  success?: (data: T) => void
  error?: (error: APIError) => void
}

export function useRequest<T> (fetcher: () => Promise<T>, events: UseRequestEvents<T> = {}): UseRequest<T> {
  const data = ref<T>()
  const err = ref<APIError>()
  const loading = ref(false)

  async function exec (): Promise<void> {
    try {
      err.value = undefined
      loading.value = true
      data.value = await fetcher()
      if (events.success !== undefined) {
        events.success(data.value)
      }
    } catch (e) {
      if (!(e instanceof APIError)) {
        throw e
      }
      err.value = e
      if (events.error !== undefined) {
        events.error(e)
      }
    } finally {
      loading.value = false
    }
  }

  // cast because of this
  // https://github.com/vuejs/vue-next/issues/1324
  return reactive({
    exec,
    data,
    err,
    loading
  }) as UseRequest<T>
}
