(function () {
  Array.from(document.querySelectorAll('.js-gallery')).forEach((g) => {
    const gallery = g as HTMLUListElement
    const scroll = gallery.querySelector('.js-gallery-scroll')
    if (scroll === null) {
      console.error('missing .js-gallery-scroll element')
      return
    }
    const passive = {
      passive: true
    }

    let mousedown: boolean
    let lastClientX: number

    // Get scroll position
    const update = (): void => {
      gallery.dataset.scrollLeft = Math.max(scroll.scrollLeft, 0).toString()
      gallery.dataset.scrollRight = Math.max(scroll.scrollWidth - scroll.clientWidth - scroll.scrollLeft, 0).toString()
    }
    scroll.addEventListener('scroll', update, passive)
    window.addEventListener('resize', update, passive)
    update()

    // Drag with mouse
    scroll.addEventListener('mousedown', (ev) => {
      const event = ev as MouseEvent
      // Only left button
      if (event.which !== 1) return
      mousedown = true
      lastClientX = event.clientX
      event.preventDefault()
    })

    window.addEventListener('mouseup', () => {
      mousedown = false
      gallery.classList.remove('is-scrolling')
    }, passive)

    window.addEventListener('mousemove', (event) => {
      if (mousedown) {
        gallery.classList.add('is-scrolling')
        scroll.scrollLeft -= -lastClientX + (lastClientX = event.clientX)
      }
    }, passive)
  })
})()
