import { def, defAsync, defAsyncSuspensible } from '@/utils/custom-elements-helper'
import { defineCustomElement } from '@/utils/api-custom-elements'

import AppContext from '@/contexts/AppContext'
import ProjectContext from '@/contexts/ProjectContext'

import CreateButton from '@/components/CreateButton.ce.vue'
import UserStatus from '@/components/UserStatus.ce.vue'

/*
** By convention, we use the prefix "frm-" for our Custom Elements
** in order to provide a namespace on our components.
** NB: "frm" is a short for "forum".
*/

/* disabling this rule to remove unnecessary explicit in this context */
/* eslint-disable @typescript-eslint/promise-function-async */

// Use shadow root on context components in order to use native slot elements
// for performance improvement
customElements.define('frm-app-context', defineCustomElement(AppContext))
customElements.define('frm-project-context', defineCustomElement(ProjectContext))

customElements.define('frm-create-btn', def(CreateButton))
customElements.define('frm-user-status', def(UserStatus))

// the following web components are not used on every page
// so they are loaded dynamically (only when the element is in the DOM)

customElements.define(
  'frm-btn-link',
  defAsync(() => import('@/components/buttons/BtnLink.vue'))
)

customElements.define(
  'frm-category',
  defAsync(() => import('@/components/Category.ce.vue'))
)

customElements.define(
  'frm-category-list',
  defAsync(() => import('@/components/CategoryList.ce.vue'))
)

customElements.define(
  'frm-contact-form',
  defAsync(() => import('@/components/ContactForm.ce.vue'))
)

customElements.define(
  'frm-participate',
  defAsync(() => import('@/components/Participate.ce.vue'))
)

customElements.define(
  'frm-search',
  defAsync(() => import('@/components/Search.ce.vue'))
)

customElements.define(
  'frm-search-input',
  defAsync(() => import('@/components/SearchInput.ce.vue'))
)

customElements.define(
  'frm-discussions',
  defAsync(() => import('@/components/Discussions.ce.vue'))
)

customElements.define(
  'frm-instagram-feed',
  defAsync(() => import('@/components/InstagramFeed.ce.vue'))
)

customElements.define(
  'frm-twitter-feed',
  defAsync(() => import('@/components/TwitterFeed.ce.vue'))
)

customElements.define(
  'frm-subscribe',
  defAsync(() => import('@/components/Subscribe.ce.vue'))
)

customElements.define(
  'frm-subscribe-btn',
  defAsync(() => import('@/components/SubscribeButton.ce.vue'))
)

customElements.define(
  'frm-related-content',
  defAsync(() => import('@/components/RelatedContent.ce.vue'))
)

customElements.define(
  'frm-featured-in',
  defAsync(() => import('@/components/FeaturedIn.ce.vue'))
)

customElements.define(
  'frm-project-follow',
  defAsync(() => import('@/components/ProjectFollow.ce.vue'))
)

customElements.define(
  'frm-collection-follow',
  defAsync(() => import('@/components/CollectionFollow.ce.vue'))
)

customElements.define(
  'frm-bookmark',
  defAsync(() => import('@/components/Bookmark.ce.vue'))
)

customElements.define(
  'frm-user-bookmarks',
  defAsync(() => import('@/components/UserBookmarks.ce.vue'))
)

customElements.define(
  'frm-project-contributors',
  defAsync(() => import('@/components/ProjectContributors.ce.vue'))
)

customElements.define(
  'frm-project-description',
  defAsync(() => import('@/components/ProjectDescription.ce.vue'))
)

customElements.define(
  'frm-project-discussion',
  defAsync(() => import('@/components/ProjectDiscussion.ce.vue'))
)

customElements.define(
  'frm-project-repository',
  defAsync(() => import('@/components/ProjectRepository.ce.vue'))
)

customElements.define(
  'frm-project-repository-url',
  defAsync(() => import('@/components/ProjectRepositoryUrl.ce.vue'))
)

customElements.define(
  'frm-project-readme',
  defAsync(() => import('@/components/ProjectReadme.ce.vue'))
)

customElements.define(
  'frm-project-topics',
  defAsync(() => import('@/components/ProjectTopics.ce.vue'))
)

customElements.define(
  'frm-project-download',
  defAsync(() => import('@/components/ProjectDownload.ce.vue'))
)

customElements.define(
  'frm-project-release-note',
  defAsync(() => import('@/components/ProjectReleaseNote.ce.vue'))
)

customElements.define(
  'frm-project-create',
  defAsyncSuspensible(() => import('@/components/ProjectCreate.ce.vue'))
)

customElements.define(
  'frm-project-general',
  defAsyncSuspensible(() => import('@/components/ProjectGeneral.ce.vue'), false)
)

customElements.define(
  'frm-project-image',
  defAsyncSuspensible(() => import('@/components/ProjectImage.vue'), false)
)

customElements.define(
  'frm-project-edit',
  defAsyncSuspensible(() => import('@/components/ProjectEdit.ce.vue'))
)

customElements.define(
  'frm-project-versions-wrapper',
  defAsyncSuspensible(() => import('@/components/ProjectVersionsWrapper.ce.vue'), true)
)

customElements.define(
  'frm-project-versions',
  defAsyncSuspensible(() => import('@/components/ProjectVersions.ce.vue'), true)
)

customElements.define(
  'frm-project-external-versions',
  defAsyncSuspensible(() => import('@/components/ProjectExternalVersions.ce.vue'))
)

customElements.define(
  'frm-project-releases',
  defAsyncSuspensible(() => import('@/components/ProjectReleases.ce.vue'))
)

customElements.define(
  'frm-event-create',
  defAsyncSuspensible(() => import('@/components/EventCreate.ce.vue'))
)

customElements.define(
  'frm-event-edit',
  defAsyncSuspensible(() => import('@/components/EventEdit.ce.vue'))
)

customElements.define(
  'frm-article-create',
  defAsyncSuspensible(() => import('@/components/ArticleCreate.ce.vue'))
)

customElements.define(
  'frm-article-edit',
  defAsyncSuspensible(() => import('@/components/ArticleEdit.ce.vue'))
)

customElements.define(
  'frm-content-anchors',
  defAsync(() => import('@/components/ContentAnchors.ce.vue'))
)

customElements.define(
  'frm-back-to-top',
  defAsync(() => import('@/components/BackToTop.ce.vue'))
)

customElements.define(
  'frm-profile-name',
  defAsync(() => import('@/components/ProfileName.ce.vue'))
)

customElements.define(
  'frm-profile-biography',
  defAsync(() => import('@/components/ProfileBiography.ce.vue'))
)

customElements.define(
  'frm-profile-links',
  defAsync(() => import('@/components/ProfileLinks.ce.vue'))
)

customElements.define(
  'frm-profile-avatar',
  defAsync(() => import('@/components/ProfileAvatar.ce.vue'))
)

customElements.define(
  'frm-profile-button',
  defAsync(() => import('@/components/ProfileButton.ce.vue'))
)

customElements.define(
  'frm-profile-membership',
  defAsync(() => import('@/components/ProfileMembership.ce.vue'))
)

customElements.define(
  'frm-profile-settings',
  defAsync(() => import('@/components/ProfileSettings.ce.vue'))
)

customElements.define(
  'frm-profile-shop',
  defAsync(() => import('@/components/ProfileShop.ce.vue'))
)

customElements.define(
  'frm-profile-content-list',
  defAsync(() => import('@/components/ProfileContentList.ce.vue'))
)

customElements.define(
  'frm-user-avatar',
  defAsync(() => import('@/components/UserAvatar.ce.vue'))
)

customElements.define(
  'frm-newsletter-subscription',
  defAsync(() => import('@/components/NewsletterSubscription.vue'))
)
