// toggle the header menu on mobile
(function () {
  const body = document.body
  const header = document.querySelector('.js-header')
  if (header === null) { return }
  const toggle = header.querySelector('.js-header-toggle')
  if (toggle === null) { return }

  let offsetY = 0

  toggle.addEventListener('click', (e) => {
    e.preventDefault()

    header.classList.toggle('is-open')

    if (header.classList.contains('is-open')) {
      offsetY = window.pageYOffset
      body.style.top = `-${offsetY}px`
      body.classList.add('has-navigation')
    } else if (body.classList.contains('has-navigation')) {
      body.classList.remove('has-navigation')
      window.scrollTo(0, offsetY)
    }
  })
})()
