<template>
  <Popover theme="white">
    <template #button>
      <slot />
    </template>
    <template #tooltip>
      <ul>
        <li class="link"><a href="/projects/create/" :title="t('createProject')">{{ t('project') }}</a></li>
        <li class="link"><a href="/guest-article/create/" :title="t('createArticle')">{{ t('article') }}</a></li>
        <li class="link"><a href="/guest-event/create/" :title="t('createEvent')">{{ t('event') }}</a></li>
      </ul>
    </template>
  </Popover>
</template>

<script setup lang="ts">
import { useI18n } from '@/utils/i18n'
import Popover from '@/components/Popover.vue'

const { t } = useI18n({
  messages: {
    en: {
      createProject: 'Create a project',
      project: 'Project',
      createArticle: 'Create an article',
      article: 'Article',
      createEvent: 'Create an event',
      event: 'Event'
    },
    fr: {
      createProject: 'Créer un projet',
      project: 'Projet',
      createArticle: 'Créer un article',
      article: 'Article',
      createEvent: 'Créer un évènement',
      event: 'Évènement'
    }
  }
})
</script>

<style scoped lang="scss">
@use "sass:color";
@use "@/sass/settings";
@use "sass-rem" as rem;

.link a {
  display: block;
  height: 100%;
  width: 100%;
  padding: rem.convert(12px);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
}

.link a:hover {
  color: settings.$color-primary;
}

.link:not(:last-child) {
  border-bottom: 1px solid color.adjust(settings.$color-grey, $alpha: -0.9);
}
</style>
