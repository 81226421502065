import { ref, computed, readonly, onMounted, onUnmounted, Ref, ComputedRef } from 'vue'
import { IRCAM_AUTH_URL } from '@/utils/env'

export function useCurrentLocation (): Readonly<Ref<Location>> {
  const currentLocation = ref(window.location)

  function onPopstate (): void {
    currentLocation.value = window.location
  }

  onMounted(() => {
    window.addEventListener('popstate', onPopstate)
  })
  onUnmounted(() => {
    window.removeEventListener('popstate', onPopstate)
  })

  return readonly(currentLocation)
}

interface AuthURL {
  loginURL: ComputedRef<string>
  logoutURL: ComputedRef<string>
}

export function useAuthURL (): AuthURL {
  const currentLocation = useCurrentLocation()
  // e.g. /accounts/ircamauth/login?next=/some-page
  const loginURL = computed(() => {
    const url = new URL('/accounts/ircamauth/login/', window.location.href)
    url.searchParams.set('next', currentLocation.value.pathname)
    return url.toString()
  })

  // e.g. https://auth.ircam.fr/logout/?next=https://forum.ircam.fr/profile/some-username/
  const logoutURL = computed(() => {
    const url = new URL('/logout', IRCAM_AUTH_URL)
    url.searchParams.set('next', currentLocation.value.toString())
    return url.toString()
  })

  return {
    loginURL,
    logoutURL
  }
}

export function cleanGithubUrl (url: string): string {
  if (url.includes('github') && url.endsWith('.git')) {
    return url.slice(0, -4)
  } else {
    return url
  }
}

export function urlExists (url: string): boolean {
  if (url === null) {
    return false
  }

  const xhr = new XMLHttpRequest()
  let response = false

  xhr.onerror = function (err: unknown) {
    if (err instanceof Error) {
      response = false
    }
    throw err
  }

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        response = true
      } else {
        console.log(`Warning: url "${url}" does not exists`)
        response = false
      }
    }
  }

  xhr.open('HEAD', url, false)
  xhr.send()

  return response
}

export function parseContentUrl (contentType: string, slug: string): string {
  const contentUrl = ref('')
  contentType = contentType.charAt(0).toUpperCase() + contentType.slice(1)

  if (contentType === 'Article') {
    contentUrl.value = '/article/detail/' + slug + '/'
  } else if (contentType === 'Event') {
    contentUrl.value = '/agenda/' + slug + '/detail/'
  } else if (contentType === 'Project') {
    contentUrl.value = '/projects/detail/' + slug + '/'
  } else if (contentType === 'Collection') {
    contentUrl.value = '/collections/detail/' + slug + '/'
  } else if (contentType === 'ForumUser') {
    contentUrl.value = '/profile/' + slug + '/'
  }

  return contentUrl.value
}
