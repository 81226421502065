<template>
  <li class="c-header__item c-header__item--user u-mrs">
    <div style="display: inherit">
      <template v-if="isLogged" />
      <a v-else :href="loginURL" class="c-header__link">
        <span class="c-header__label">{{ t('login') }}</span>
      </a>
      <template v-if="isLogged">
        &nbsp;<a :href="logoutURL" class="c-header__link"><span class="c-header__label">{{ t('signOut') }}</span></a>
      </template>
    </div>
  </li>
</template>

<script setup lang="ts">
import { useAuthURL } from '@/utils/urls'
import { useI18n } from '@/utils/i18n'
import { useAuth } from '@/utils/auth'

const { t } = useI18n({
  messages: {
    en: {
      login: 'Login',
      signOut: 'Sign Out'
    },
    fr: {
      login: 'Se connecter',
      signOut: 'Déconnexion'
    }
  }
})
const { isLogged } = useAuth()
const { loginURL, logoutURL } = useAuthURL()
</script>

<style scoped>
.container {
  margin-right: 2rem;
}

.user-symbol {
  fill: #30b2f2;
  margin-left: .3rem;
}
</style>
