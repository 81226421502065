import { ref, Ref, InjectionKey } from 'vue'
import { deepInject } from '@/utils/context'

/*
** Light re-implementation of vue-i18n interface because of
** https://github.com/intlify/vue-i18n-next/issues/646
** https://github.com/intlify/vue-i18n-next/issues/771
**
** TL:DR: Better typing, support for custom elements architecture
*/

export const I18nInjectionKey: InjectionKey<I18n> = Symbol('i18n')

interface CreateI18n {
  locale: Locale
  fallbackLocale: Locale
}

interface I18n {
  locale: Ref<Locale>
  fallbackLocale: Ref<Locale>
}

export function createI18n (options: CreateI18n): I18n {
  return {
    locale: ref(options.locale),
    fallbackLocale: ref(options.fallbackLocale)
  }
}

export type Locale = 'en' | 'fr'

// Option is expected to be compatible with vue-i18n
interface Options<T extends string = string> {
  messages: Record<Locale, Record<T, string>>
}

interface UseI18N<T> {
  locale: Ref<Locale>
  t: (key: T, replace?: Record<string, string>) => string
}

const mustache = (str: string, data: Record<string, string>): string => {
  for (const key in data) {
    str = str.replace(new RegExp('{\\s*' + key + '\\s*}', 'g'), data[key])
  }
  return str
}

export function useI18n<T extends string> (options: Options<T>): UseI18N<T> {
  const instance = deepInject(I18nInjectionKey)
  if (instance === undefined) {
    throw new Error('i18n not found in context')
  }

  const { locale, fallbackLocale } = instance

  function t (key: T, replace?: Record<string, string>): string {
    let msg = options.messages[locale.value][key]
    if (msg === undefined) {
      msg = options.messages[fallbackLocale.value][key]
    }
    if (replace !== undefined) {
      msg = mustache(msg, replace)
    }
    return msg
  }

  return {
    locale,
    t
  }
}
