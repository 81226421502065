import { h, defineAsyncComponent, AsyncComponentLoader } from 'vue'
import { defineCustomElement } from '@/utils/api-custom-elements'
import { toNumber } from '@vue/shared'

import SuspenseWrapper from '@/components/SuspenseWrapper.vue'

// Small wrappers funcs to reduce boilerplate
type Comp = Parameters<typeof defineCustomElement>[0]
type CE = ReturnType<typeof defineCustomElement>

// helper to define custom element without shadow dom
export function def (comp: Comp): CE {
  return defineCustomElement(comp, { shadowRoot: false })
}

// helper to define async custom element without shadow dom
export function defAsync (asyncComp: AsyncComponentLoader): CE {
  return defineCustomElement(defineAsyncComponent(asyncComp), { shadowRoot: false })
}

type Attrs = Record<string, any>
function attrsToNumber (attrs: Attrs): Attrs {
  const newAttrs: Record<string, any> = {}
  for (const a in attrs) {
    const numberProps = [ 'projectId', 'eventId', 'articleId' ]
    if (numberProps.includes(a)) {
      newAttrs[a] = toNumber(attrs[a])
    } else {
      newAttrs[a] = attrs[a]
    }
  }
  return newAttrs
}

// helper to define async component with loading icon during Suspense
// it shows a loading spinner while the component is :
// - being fetched by the browser
// - the async setup function is being resolved
// see https://github.com/vuejs/vue-next/issues/5174
export function defAsyncSuspensible (asyncComp: AsyncComponentLoader, showLoadingIcon?: boolean): CE {
  return defineCustomElement({
    render () {
      if (typeof showLoadingIcon === 'undefined') {
        showLoadingIcon = true
      }
      return h(SuspenseWrapper, { showLoadingIcon }, {
        default: () => {
          // we manually convert some props to number to avoid vue warning "invalid prop type".
          // ideally, Vue would convert them automatically, but it seems quite tricky
          return h(defineAsyncComponent(asyncComp), attrsToNumber(this.$attrs))
        }
      })
    }
  }, { shadowRoot: false })
}
