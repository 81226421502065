import { ref, readonly, Ref } from 'vue'
import { request, requestJSON } from '@/utils/api'
import { IRCAM_AUTH_URL, authenticated } from '@/utils/env'

interface User {
  username: string
  pk?: number
}

interface UseAuth {
  isLogged: Readonly<Ref<boolean>>
  user: Readonly<Ref<undefined|User>>
}

const isLogged = ref(false)
const user = ref<User>()

// Get authenticated status from IRCAM Auth
async function getAuthStatus (): Promise<boolean> {
  // e.g. https://auth.ircam.fr/auth_status/ in production
  const url = new URL('/auth_status/', IRCAM_AUTH_URL)
  const resp = await fetch(url.toString(), { credentials: 'include' })
  const json: { authenticated: boolean } = await resp.json()
  return json.authenticated
}

async function getUser (): Promise<User> {
  return await requestJSON('/api/me')
}

async function logout (): Promise<void> {
  if (authenticated) {
    await request('/api/logout', { method: 'POST' })
  }
}

// check every 20s if user is still logged on auth.ircam.fr
// if not, disconnect it from forum.ircam.fr
function startPolling (): void {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const interval = setInterval(async () => {
    const logged = await getAuthStatus()
    if (logged) {
      return
    }
    await logout()
    clearInterval(interval)
    isLogged.value = false
  }, 20 * 1000)
}

async function onLoad (): Promise<void> {
  const logged = await getAuthStatus()
  if (!logged) {
    await logout()
    return
  }
  user.value = await getUser()
  isLogged.value = true
  startPolling()
}

// use ref inside components
export function useAuth (): UseAuth {
  return {
    isLogged: readonly(isLogged),
    user: readonly(user)
  }
}

// init polling
if (document.readyState !== 'loading') {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  onLoad()
} else {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  document.addEventListener('DOMContentLoaded', () => { onLoad() }, false)
}
