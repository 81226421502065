/*
** ProjectContext fetches project routes and inject it to be
** used by child components.
** It avoids fetching the same data multiple times on one page.
*/
import {
  defineComponent,
  onMounted,
  provide,
  computed,
  h,
  Ref,
  InjectionKey
} from 'vue'
import {
  useRequest,
  UseRequest,
  Project,
  Repository,
  Download,
  getProject,
  getProjectRepo,
  getProjectDownload
} from '@/utils/api'
import { deepInject } from '@/utils/context'

type ProjectInjected = Ref<Project | undefined>
const ProjectInjectionKey: InjectionKey<ProjectInjected> = Symbol('forum-project')

export function useProjectContext (): ProjectInjected {
  const p = deepInject(ProjectInjectionKey)
  if (p === undefined) {
    throw new Error('component is not a child of ProjectContext')
  }
  return p
}

type RepositoryInjected = UseRequest<Repository>
const RepositoryInjectionKey: InjectionKey<RepositoryInjected> = Symbol('forum-repo')

export function useRepositoryContext (): RepositoryInjected {
  const r = deepInject(RepositoryInjectionKey)
  if (r === undefined) {
    throw new Error('component is not a child of ProjectContext or get-repo prop is false')
  }
  return r
}

type DownloadInjected = UseRequest<Download>
const DownloadInjectionKey: InjectionKey<DownloadInjected> = Symbol('forum-download')

export function useDownloadContext (): DownloadInjected {
  const d = deepInject(DownloadInjectionKey)
  if (d === undefined) {
    throw new Error('component is not a child of ProjectContext or get-download prop is false')
  }
  return d
}

function provideProject (projectId: number): void {
  const req = useRequest(async () => await getProject(projectId))
  provide(ProjectInjectionKey, computed(() => req.data))
  onMounted(async () => await req.exec())
}

function provideRepo (projectId: number): void {
  const req = useRequest(async () => await getProjectRepo(projectId))
  provide(RepositoryInjectionKey, req)
  onMounted(async () => await req.exec())
}

function provideDownload (projectId: number): void {
  const req = useRequest(async () => await getProjectDownload(projectId))
  provide(DownloadInjectionKey, req)
  onMounted(async () => { await req.exec() })
}

export default defineComponent({
  props: {
    projectId: {
      type: Number,
      required: true
    },
    getRepo: {
      type: Boolean,
      required: false,
      default: false
    },
    getDownload: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    provideProject(props.projectId)

    if (props.getRepo) {
      provideRepo(props.projectId)
    }

    if (props.getDownload) {
      provideDownload(props.projectId)
    }
  },
  render () {
    return h('slot')
  }
})
