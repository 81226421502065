(function () {
  Array.from(document.querySelectorAll('.js-message')).forEach((alert) => {
    const close = alert.querySelector('.js-message-close')
    if (close === null) {
      console.error('missing .js-message-close element')
      return
    }
    close.addEventListener('click', (event) => {
      event.preventDefault()
      const p = alert.parentNode
      if (p === null) {
        console.error('missing parentNode')
        return
      }
      p.removeChild(alert)
    })
  })
})()
