<template>
  <div v-if="error" class="u-color-error">
    {{ error }}
  </div>
  <suspense v-else>
    <template #default>
      <slot />
    </template>
    <template #fallback>
      <LoadingIcon v-if="showLoadingIcon" />
    </template>
  </suspense>
</template>

<script setup lang="ts">
import { ref, onErrorCaptured, toRefs } from 'vue'
import LoadingIcon from '@/components/LoadingIcon.vue'

const props = defineProps<{
  showLoadingIcon?: boolean
}>()

const { showLoadingIcon } = toRefs(props)

const error = ref<Error | null>(null)
onErrorCaptured(e => {
  error.value = e
  return true
})
</script>
