<template>
  <svg class="c-svg__icon" :width="width" :height="height" aria-hidden="true">
    <use :href="symbolId" :fill="color" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'

// Generated list of available names until fixed
// https://github.com/vbenjs/vite-plugin-svg-icons/issues/22#issuecomment-1024392031
// to get the array of available icons, uncomment :
// import ids from 'virtual:svg-icons-names'
// console.log(ids)

const ids = [
  'add',
  'arrow-expand',
  'arrow-left',
  'arrow-left-white',
  'arrow-right',
  'arrow-right-white',
  'arrow-shrink',
  'arrow-up',
  'article',
  'avatar',
  'avatar-background',
  'avatar-outline',
  'avatar-outline-thick',
  'bell-off',
  'bell',
  'box',
  'calendar',
  'cancel',
  'cancel-black',
  'cancel-circle',
  'cancel-square',
  'carousel',
  'cart',
  'chat',
  'chat-white',
  'check',
  'checkbox-checked',
  'checkbox-unchecked',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'crown',
  'diamond',
  'diamond-small',
  'content',
  'download',
  'eye-off',
  'eye',
  'facebook-blue',
  'facebook-square',
  'feather-pen',
  'flag',
  'flickr',
  'folder',
  'forked',
  'heart',
  'heart-large',
  'ircam-forum',
  'link-blue',
  'link-off',
  'link',
  'list',
  'loading',
  'logo-cgp-white',
  'logo-forum-white',
  'mail',
  'memo',
  'menu',
  'padlock',
  'pen',
  'pencil',
  'pin',
  'pen',
  'play',
  'plus',
  'plus-square',
  'plus-square-white',
  'search',
  'send-down',
  'send-in',
  'send-up',
  'settings',
  'spinner-thin',
  'star',
  'star-empty',
  'star-full',
  'tag',
  'team',
  'time',
  'trash',
  'twitter',
  'twitter-blue',
  'upload',
  'user',
  'warning',
  'youtube'
] as const

type IconName = typeof ids[number]

// small wrapper to insert SVG icons from spritesheet
// see https://www.npmjs.com/package/vite-plugin-svg-icons

const props = withDefaults(defineProps<{
  name: IconName
  width?: string | number
  height?: string | number
  prefix?: string
  color?: string
}>(), {
  width: '24',
  height: '24',
  prefix: 'icon',
  color: undefined
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
</script>
