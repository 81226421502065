<template>
  <div ref="button" class="button-container" v-on="buttonEvents">
    <slot name="button" />
  </div>
  <div
    ref="tooltip"
    :class="['tooltip', `theme-${theme}`]"
    :style="tooltipStyle"
    :data-show="showTooltip || null"
    :aria-hidden="!showTooltip"
  >
    <div ref="arrowEl" class="tooltip-arrow" :style="arrowStyle" />
    <div class="tooltip-inner">
      <slot :is-open="showTooltip" name="tooltip" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch, watchPostEffect, computed } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { Placement } from '@floating-ui/core'
import { computePosition, flip, shift, offset, arrow } from '@floating-ui/dom'

type Theme = 'info' | 'danger' | 'black' | 'white'
type Trigger = 'click' | 'hover' | 'manual'

// when trigger == 'manual', a `show` props is expected
// not possible to define this typing with defineProps
// as complex types are not supported (e.g. `PropsA | PropB`)
// see https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md#type-only-propsemit-declarations
const props = withDefaults(defineProps<{
  placement?: Placement
  theme?: Theme
  trigger?: Trigger
  show?: boolean
}>(), {
  placement: 'bottom',
  theme: 'info',
  trigger: 'click'
})

const emit = defineEmits<{
  (e: 'click-outside'): void
}>()

const buttonEvents = computed(() => {
  if (props.trigger === 'click') {
    return {
      click: () => { isOpen.value = !isOpen.value }
    }
  } else if (props.trigger === 'hover') {
    return {
      mouseenter: () => { isOpen.value = true },
      focus: () => { isOpen.value = true },
      mouseleave: () => { isOpen.value = false },
      blur: () => { isOpen.value = false }
    }
  }
  return {}
})

const showTooltip = computed<boolean>(() => {
  if (props.trigger === 'manual') {
    if (props.show === undefined) {
      throw new Error('when trigger is manual, show prop should be defined')
    }
    return props.show
  }
  return isOpen.value
})

const isOpen = ref(false)
const tooltip = ref<HTMLElement>()
const button = ref<HTMLElement>()
const arrowEl = ref<HTMLDivElement>()

function clickOutside () {
  emit('click-outside')
  isOpen.value = false
}

// https://stackoverflow.com/a/153047
const stopPropagation = (e: Event) => e.stopPropagation()
function startCloseOnClick () {
  window.addEventListener('click', clickOutside)
  tooltip.value!.addEventListener('click', stopPropagation)
}
function stopCloseOnClick () {
  window.removeEventListener('click', clickOutside)
  if (tooltip.value) {
    tooltip.value.removeEventListener('click', stopPropagation)
  }
}

const tooltipStyle = ref({})
const arrowStyle = ref({})

type Side = 'top' | 'bottom' | 'left' | 'right'

const sideFromPlacement = (placement: Placement): Side => {
  const sides = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right'
  }
  const p = placement.split('-')[0] as Side
  return sides[p] as string as Side
}

const setTooltipStyle = async () => {
  if (tooltip.value === undefined) { throw new Error('tooltip is undefined') }
  if (button.value === undefined) { throw new Error('button is undefined') }
  if (arrowEl.value === undefined) { throw new Error('arrow is undefined') }

  const offsetVal = props.theme === 'white' ? 0 : 15
  // https://floating-ui.com/docs/tutorial
  const config = {
    placement: props.placement,
    middleware: [
      offset(offsetVal),
      // flip placement if not enough space for required placement
      flip(),
      // shift horizontally if not enough space to display all content
      shift({ padding: 5 }),
      arrow({ element: arrowEl.value })
    ]
  }
  const pos = await computePosition(button.value, tooltip.value, config)
  const { x, y, middlewareData, placement } = pos
  tooltipStyle.value = {
    left: `${x}px`,
    top: `${y}px`
  }

  // arrow position
  const { x: arrowX, y: arrowY } = middlewareData.arrow!
  const staticSide = sideFromPlacement(placement)
  arrowStyle.value = {
    left: arrowX !== undefined ? `${arrowX}px` : '',
    top: arrowY !== undefined ? `${arrowY}px` : '',
    [staticSide]: '-4px'
  }
}

onMounted(() => {
  watch(showTooltip, () => {
    if (showTooltip.value === true) {
      setTooltipStyle()
    }
  })
  useResizeObserver(document.body, () => {
    setTooltipStyle()
  })
})

watchPostEffect(() => {
  if (showTooltip.value) {
    window.setTimeout(startCloseOnClick, 0)
  } else {
    stopCloseOnClick()
  }
})

onUnmounted(() => {
  stopCloseOnClick()
})
</script>

<style lang="scss" scoped>
@use "sass:math";
@use "sass:color";
@use "sass-rem" as rem;
@use "@/sass/settings";
@use "@/sass/helpers/mq";
@use "@/sass/helpers/font";

$color: #f9f9f9;

.button-container {
  display: inline-flex;
  vertical-align: middle;
}

.c-banner__title .button-container {
  vertical-align: bottom;
}

.tooltip {
  position: absolute;
  opacity: 0;
  transition: opacity .15s;
  pointer-events: none;

  z-index: 10000;
  box-shadow: rem.convert(0px) rem.convert(1px) rem.convert(5px) rgba(0, 0, 0, 0.3);

  &[data-show] {
    opacity: 1;
    pointer-events: visible;
  }

  --bg-color: #{settings.$color-lightgrey};
  --text-color: black;
  font-family: settings.$font-default;

  @include mq.mq($from: large) {
    min-width: rem.convert(350px);
  }
}

.tooltip-inner {
  background: var(--bg-color);
  color: var(--text-color);
  padding: 24px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

/* arrow */
.tooltip-arrow,
.tooltip-arrow::before {
  $arrow-width: 15px;
  margin: 0;
  position: absolute;
  width: $arrow-width;
  height: $arrow-width;
  background: var(--bg-color);
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.tooltip.theme-info {
  --bg-color: #{settings.$color-lightgrey};
  --text-color: black;

  box-shadow: none;

  .tooltip-inner {
    border-radius: 0;
    padding: 18px;
  }
}

.tooltip.theme-danger {
  --bg-color: #{settings.$color-red};
  --text-color: white;

  min-width: auto;

  .tooltip-inner {
    border-radius: .125rem;
    padding: .5rem;
    font-size: .875rem;
    line-height: 1.7142857143;
  }
}

.tooltip.theme-black {
  --bg-color: black;
  --text-color: white;

  .tooltip-inner {
    border: 0;
    border-radius: .125rem;
    padding: .125rem .5rem;
    font-size: .875rem;
    line-height: 1.7142857143;
  }
}

.tooltip.theme-white {
  --bg-color: #{settings.$color-background};
  --text-color: black;

  box-shadow: none !important;

  .tooltip-arrow {
    display: none;
  }

  min-width: auto;
  width: rem.convert(135px);
  @include mq.mq($from: large) {
    width: rem.convert(178px);
  }

  @include font.size(16px, 24px);
  @include mq.mq($from: medium) {
    @include font.size(18px, 26px);
  }

  .tooltip-inner {
    box-shadow: rem.convert(0px) rem.convert(5px) rem.convert(30px) rem.convert(-10px) rgba(0,0,0,0.1);
    border: 1px solid color.adjust(settings.$color-grey, $alpha: -0.8);
    border-radius: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
</style>
