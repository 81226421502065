/*
** AppContext is expected to be the parent component of every other components
** in this library.
** It injects global utils shared between all components.
*/

import { defineComponent, provide, h } from 'vue'
import { createI18n, I18nInjectionKey, Locale } from '@/utils/i18n'

export default defineComponent({
  setup () {
    const i18n = createI18n({
      // get the current user language from html's lang attribute, set from django template
      locale: document.documentElement.lang as Locale,
      fallbackLocale: 'en'
    })
    provide(I18nInjectionKey, i18n)
  },
  render () {
    return h('slot')
  }
})
